<template>
  <div style="min-height: calc(100vh - 20px);">
    <div>
      <b-card class="justify-content-center no-gutters">
        <b-row class="no-gutters justify-content-md-center" style="min-height: 100vh;" align-v="center">
          <b-col class="align-items-center" sm="12" md="6" lg="5">
            <div>
              <b-row align-h="between">
                <b-col lg="6" sm="12">
                  <h3 class="mt-2">{{ $t('account.verify') }}</h3>
                </b-col>
                <b-col lg="5" sm="12" align="right">
                  <language/>
                </b-col>
              </b-row>
              <div class="mb-2">
                <div class="mt-1 mb-1 text-bold">{{$t('account.please_enter_phone')}}</div>
                <small>{{$t('account.content_for_verify_phone')}}</small>
              </div>
              <input-component
                  :placeholder="$t('account.verify_otp.phone_otp')"
                  max="11"
                  required
                  :disabled-type="is_req_otp"
                  :require-detail="dupPhone"
                  @keypress="checkIsNumber"
                  @input="handleSearchInput"
                  v-model="$v.form.phone.$model"
                  :state="validateState('phone') && telState && dupPhone === ''"
                  :require-label="validateState('phone') !== null && !$v.form.phone.required  ? $t('common.requiredField') : !telState ? telInvalid : dupPhone !== '' ?  dupPhone : ''"
              />
            </div>
            <div v-show="!is_req_otp" class="mt-1">
              <div>
                <recaptcha-component
                    @verify="onVerify"
                    :resetToken="resetTokenOTP"
                    @token="getTokenReCap($event, 'register')"
                />
              </div>
            </div>
            <div v-if="is_req_otp">
              <div class="mb-4">
                <otp-input
                    ref="otpInput"
                    id="otpInput"
                    :input-classes="error_req_otp ?  'otp-input-register-error':  'otp-input-register'"
                    separator=" "
                    :num-inputs="6"
                    :should-auto-focus="true"
                    :is-input-num="true"
                    input-type="number"
                    @on-change="onChangedHandlerOTP"
                    @on-complete="reVerifyRegister"
                />
                <div class="text-left text-danger mt-2" v-if="error_req_otp">
                  {{ error_req_otp }}
                </div>
              </div>
              <div class="text-center">
                {{ $t('account.verify_otp.enter_otp_code') }}
                <span>{{ $t('SMS') }}</span>
                {{ $t('account.verify_otp.verify_identity') }}
              </div>
              <div class="text-primary text-center">
                Ref Code : <span>{{ ref_code }}</span> (
                {{ $t('account.verify_otp.exp_code') + ' ' + rate_time + ' ' + $t('common.minute') }} )
              </div>
            </div>
            <div v-show="is_req_otp && time === '00:00' || !is_req_otp && time === '00:00' && !resetTokenOTP && !tokenRecapOTP">
              <div>
                <recaptcha-component
                    @verify="onVerify"
                    :resetToken="resetTokenOTP"
                    @token="getTokenReCap($event, 'resend')"
                />
              </div>
            </div>
            <div>
              <b-row>
                <b-col sm="12" md="6" lg="6">
                  <b-button
                      variant="outline"
                      pill
                      block
                      class="mt-4 mb-3"
                      @click="backToLogin"
                  >
                    {{ $t('forgotPassword.backToLogin') }}
                  </b-button>
                </b-col>
                <b-col sm="12" md="6" lg="6">
                  <div  class="mt-4 mb-3" v-if="is_req_otp">
                    <b-button
                        v-if="is_req_otp && time !== '00:00' || !is_req_otp && (resetTokenOTP && !tokenRecapOTP)"
                        variant="primary"
                        pill
                        block
                        disabled>
                      {{ $t('account.verify_otp.new_req_otp') + " (" + time + ")" }}
                    </b-button>
                    <b-button v-else
                              variant="primary"
                              pill
                              block
                              @click="reqOtpRegister"
                              :disabled="isInvalidReqOtp || loading_req_otp">
                      {{
                        is_req_otp && time === '00:00' ? $t('account.verify_otp.new_req_otp') :
                            $t('account.verify_otp.receive_otp')
                      }}
                      <b-spinner small v-if="loading_req_otp"/>
                    </b-button>
                  </div>
                  <div class="mt-4 mb-3" v-else>
                    <b-button
                              variant="primary"
                              pill
                              block
                              @click="reqOtpRegister"
                              :disabled="loading_req_otp || isInvalid">
                     {{ $t('account.verify_otp.receive_otp')}}
                      <b-spinner small v-if="loading_req_otp"/>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>
<script>
import FooterBlank from "@/layouts/full-layout/footer/FooterBlankLayout.vue";
import InputComponent from "@/components/common/inputComponentSearch.vue";
import functions from "@/common/functions";
import profileApi from "@/repository/profileApi";
import recaptchaComponent from "@/components/common/recaptchaComponent.vue";
import OtpInput from "@bachdgvn/vue-otp-input";
import functionCommon from "@/common/functions";
import {email, minLength, required, sameAs} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import moment from "moment";
import alert from "@/common/alert";
import functionsCommon from "@/common/functions";
import Language from "@/components/common/Language.vue";

export default {
  name: "checkDuplicatePhone",
  components: {Language, recaptchaComponent, InputComponent, FooterBlank, OtpInput},
  mixins: [validationMixin],
  data() {
    return {
      form: {
        phone: '',
        customer_id: ''
      },
      tokenReCap: '',
      tokenRecapOTP: '',
      resetTokenOTP: false,
      error_req_otp: false,
      is_req_otp: false,
      rate_time: 3, //default rate time
      date: moment(this.rate_time, 'minutes'),
      timeCountdown: null,
      ref_code: null,
      loading_req_otp: false,
      token: '',
      re_verify_token: '',
      package_id: null,
      custom_package: null,
      dupPhone:null,
      searchDelayTimer: null,
    }
  },
  validations: {
    form: {
      phone: {
        required,
      },
    },
  },
  computed: {
    telState() {
      return functionCommon.telState(this.form.phone)
    },
    telInvalid() {
      const data = functionCommon.telInvalid(this.form.phone)
      return this.$t(data)
    },
    isInvalidReqOtp() {
      let recaptcha_info = this.$store.getters['profile/getBandSettingMedia']?.recaptcha_information?.for_registration
      return recaptcha_info && this.is_req_otp && !this.resetTokenOTP && !this.tokenRecapOTP && this.time === '00:00' ||
          recaptcha_info && this.resetTokenOTP && !this.tokenRecapOTP ||
          recaptcha_info && !this.is_req_otp && this.time === '00:00' && !this.resetTokenOTP && !this.tokenRecapOTP
    },
    time() {
      return this.date.format('mm:ss');
    },
    isInvalid() {
      let recaptcha_info = this.$store.getters['profile/getBandSettingMedia']?.recaptcha_information?.for_registration
      return (
          this.$v.form.$invalid ||
          (recaptcha_info && !this.isVerify && this.token !== '' ||
              recaptcha_info && !this.resetToken && !this.tokenReCap) ||
          !this.telState ||  this.dupPhone !== ''
      )
    },
  },
  mounted() {
    const customer_id = localStorage.getItem('customer_id')
    const token = this.$route.query.token
    const re_verify_token = localStorage.getItem('re-verify')
    if (customer_id || re_verify_token) {
      this.form.customer_id = customer_id
      this.re_verify_token = re_verify_token
    }else if (token) {
      this.token = token
    }

    this.package_id = this.getCookie('P_ID')
    this.custom_package = this.getCookie('CUSTOM_PRICE')
    localStorage.setItem('lang', this.$i18n.locale)
  },
  methods: {
    validateState(username) {
      const {$dirty, $error} = this.$v.form[username]
      return $dirty ? !$error : null
    },
    checkIsNumber(e) {
      return functions.checkNumber(e)
    },
    reqOtpRegister() {
      let data = {}
      let recaptcha = this.tokenRecapOTP ? this.tokenRecapOTP : this.tokenReCap
      if (this.form.customer_id) {
        data = {
          phone: this.form.phone,
          type: 'phone',
          recaptcha_token: recaptcha,
          customer_id: this.form.customer_id,
        }
      } else {
        data = {
          phone: this.form.phone,
          type: 'phone',
          recaptcha_token: recaptcha,
        }
      }
      this.loading_req_otp = true
      profileApi.otpRegisterSend(data).then(resp => {
        if (!!resp) {
          this.ref_code = resp?.ref_code
          this.token = resp?.transaction_id
          this.is_req_otp = true
          this.resetTokenOTP = false
          this.tokenRecapOTP = null
          this.rate_time = resp?.validity
          this.date = moment(this.rate_time, 'minute')
          this.error_req_otp = ''
          this.setTime()
        }
      }).finally(() => {
        this.resetToken = false
        this.resetTokenOTP = false
        this.loading_req_otp = false
        this.back_step = false
        this.$refs.otpInput.clearInput();
      })
    },
    onVerify() {
      this.isVerify = true
    },
    getTokenReCap(e, key) {
      if (key === 'register') {
        this.tokenReCap = e
      } else if (key === 'resend') {
        this.tokenRecapOTP = e
      }
    },
    setTime() {
      let counter = null
      this.timeCountdown = setInterval(() => {
        this.date = moment(this.date.subtract(1, 'seconds'))
        counter = this.date.format('mm:ss')
        if (counter === '00:00') {
          clearInterval(this.timeCountdown)
        }
      }, 1000);
    },
    checkDuplicatePhone (phoneNumber) {
      const data = {
        type: "phone",
        value: phoneNumber
      }
      profileApi.checkDuplicatePhoneNumber(data).then(resp => {
        if (resp.codeSYS === '001' && !resp.success) {
          this.dupPhone = this.$t('common.phoneDup')
        } else {
          this.dupPhone = ""
        }
      }).catch(err => {
        throw err
      })
    },
    reVerifyRegister(otp) {
      const data = {
        token: this.token,
        otp_code: otp,
        ref_code: this.ref_code,
        phone: this.form.phone,
        customer_id: this.form.customer_id,
        re_verify_token: this.re_verify_token
      }
      profileApi.verifyOtpRegister(data).then(resp => {
        if (resp.codeSYS === '001') {
          this.$store.dispatch('profile/setUserInfo', resp)
          const profile = this.$store.getters["profile/getUserInfo"]
          this.$store.dispatch('profile/setName', profile?.first_name + " " + profile?.last_name)
          this.$store.dispatch('profile/setCredit', profile.sms_unit)
          this.$store.dispatch('profile/setURLTracking', profile.url_tracking)
          this.$i18n.locale = profile.locale
          alert.addNewSuccess(this)
          setTimeout(() => {
                if (!_.isNil(this.package_id) || !_.isEmpty(this.package_id)) {
                  this.$router.push(`/Subscriptions/Package?package_id=${this.package_id}&go_to=checkout`).catch(() => {
                  })
                } else if (!_.isNil(this.custom_package) || !_.isEmpty(this.custom_package)) {
                  this.$router.push(`/Subscriptions/Package?price=${this.custom_package}`).catch(() => {
                  })
                } else {
                  this.$router.push('/Dashboard').catch(() => {
                  })
                }
              }
              , 1200)
        }
      }).catch((err) => {
        let code = err?.response?.data?.errors?.code
        if (code === 436 || code === '436') {
          this.error_req_otp = this.$t('common.OTPDoesNotMatch')
        } else if (code === 1018 || code === '1018') {
          this.error_req_otp = this.$t('common.OTPExpiry')
        } else {
          this.error_req_otp = err?.response?.data?.message
        }
      })
    },
    onChangedHandlerOTP(event) {
      if (event) {
        this.error_req_otp = ''
      }
    },
    backToLogin () {
      this.$router.push('/Login')
    },
    getCookie(c_name) {
      return functionsCommon.getCookie(c_name)
    },
    handleSearchInput() {
      clearTimeout(this.searchDelayTimer);
      this.searchDelayTimer = setTimeout(() => {
        this.checkDuplicatePhone(this.form.phone)
      }, 1000);
    },
  },
  destroyed() {
    localStorage.removeItem('re-verify')
    localStorage.removeItem('customer_id')
  },
  beforeDestroy() {
    clearInterval(this.timeCountdown)
  },
}
</script>

<style scoped>
.polycy-link {
  color: var(--primary-color);
  cursor: pointer;
}

#otpInput::v-deep {
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 576px) {
    justify-content: space-around;
  }
}

#otpInput::v-deep .otp-input-register {
  width: 5.5rem;
  height: 5.5rem;
  padding: 5px;
  margin: 1rem 0 0 0;
  text-align: center;
  border-radius: 20px;
  border: 1px solid #E9E9E9;
  font-size: 36px;

  &.error {
    border: 1px solid red !important;
  }

  @media (max-width: 576px) {
    width: 3rem;
    height: 3rem;
    margin-left: 1rem;
    border-radius: 10px;
    font-size: 25px;
  }
}
#otpInput::v-deep .otp-input-register:focus {
  -webkit-appearance: none!important;
  -moz-appearance: none!important;
  appearance: none!important;
}

#otpInput::v-deep .otp-input-register-error {
  border: 1px solid #E9E9E9;
  background: #FFE9E9;
  color: red;
  width: 5.5rem;
  height: 5.5rem;
  padding: 5px;
  margin: 1rem 0 0 0;
  text-align: center;
  border-radius: 20px;
  font-size: 36px;
  @media (max-width: 576px) {
    width: 3rem;
    height: 3rem;
    margin-left: 1rem;
    border-radius: 10px;
    font-size: 25px;
  }
}
#otpInput::v-deep .otp-input-register::-webkit-inner-spin-button,
#otpInput::v-deep .otp-input-register::-webkit-outer-spin-button,
#otpInput::v-deep .otp-input-register-error::-webkit-inner-spin-button,
#otpInput::v-deep .otp-input-register-error::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>