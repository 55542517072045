<template>
  <div>
    <footer class="footer">
      Copyright &copy; <span class="text-primary text-bold">{{ BrandSetting?.footer_text || 'SMS2PRO' }}</span>
      {{ lastVersion || 'v.1.4.8' }}
    </footer>
  </div>
</template>
<script>
import Footer from "@/layouts/full-layout/footer/Footer.vue";

export default {
  name: 'footer-blank',
  components: {Footer},
  data() {
    return {}
  },
  computed: {
    lastVersion() {
      return process.env.VUE_APP_ROOT_VERSION
    },
    BrandSetting() {
      return this.$store.getters['profile/getBandSetting']
    },
  }
}
</script>
<style scoped>
.footer {
  position: relative;
  left: 50%;
  bottom: 0;
  width: 100%;
  text-align: center;
  transform: translate(-50%, 0) !important;
  margin-top: 5rem;
  font-size: 10px;
}
</style>